/*! jQuery UI - v1.10.3 - 2014-01-09
* http://jqueryui.com
* Includes: jquery.ui.core.css, jquery.ui.resizable.css, jquery.ui.selectable.css, jquery.ui.datepicker.css, jquery.ui.theme.css
* To view and modify this theme, visit http://jqueryui.com/themeroller/?ffDefault=Arial%2Csans-serif&fwDefault=bold&fsDefault=1.1em&cornerRadius=10px&bgColorHeader=%23b80108&bgTextureHeader=highlight_soft&bgImgOpacityHeader=15&borderColorHeader=%23e3a1a1&fcHeader=%23ffffff&iconColorHeader=%23ffffff&bgColorContent=%23ffffff&bgTextureContent=flat&bgImgOpacityContent=75&borderColorContent=%23eeeeee&fcContent=%23333333&iconColorContent=%23cc0000&bgColorDefault=%23b80108&bgTextureDefault=flat&bgImgOpacityDefault=100&borderColorDefault=%23b80108&fcDefault=%23ffffff&iconColorDefault=%23ffffff&bgColorHover=%23a00409&bgTextureHover=flat&bgImgOpacityHover=100&borderColorHover=%23a00409&fcHover=%23ffffff&iconColorHover=%23a00409&bgColorActive=%23ffffff&bgTextureActive=flat&bgImgOpacityActive=65&borderColorActive=%23b80108&fcActive=%23b80108&iconColorActive=%23b80108&bgColorHighlight=%23f8ed93&bgTextureHighlight=flat&bgImgOpacityHighlight=100&borderColorHighlight=%23a00409&fcHighlight=%23a00409&iconColorHighlight=%23b80108&bgColorError=%23f3d8d8&bgTextureError=diagonals_thick&bgImgOpacityError=75&borderColorError=%23cc0000&fcError=%232e2e2e&iconColorError=%23cc0000&bgColorOverlay=%23a6a6a6&bgTextureOverlay=dots_small&bgImgOpacityOverlay=65&opacityOverlay=40&bgColorShadow=%23333333&bgTextureShadow=flat&bgImgOpacityShadow=0&opacityShadow=10&thicknessShadow=8px&offsetTopShadow=-8px&offsetLeftShadow=-8px&cornerRadiusShadow=8px
* Copyright 2014 jQuery Foundation and other contributors; Licensed MIT */
/* Layout helpers
----------------------------------*/
.ui-helper-hidden {
  display: none;
}
.ui-helper-hidden-accessible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.ui-helper-reset {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  line-height: 1.3;
  text-decoration: none;
  font-size: 100%;
  list-style: none;
}
.ui-helper-clearfix:before,
.ui-helper-clearfix:after {
  content: "";
  display: table;
  border-collapse: collapse;
}
.ui-helper-clearfix:after {
  clear: both;
}
.ui-helper-clearfix {
  min-height: 0;
  /* support: IE7 */
}
.ui-helper-zfix {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  opacity: 0;
  filter: alpha(opacity=0);
}
/*.ui-front {
	z-index: 100;
}*/
/* Interaction Cues
----------------------------------*/
.ui-state-disabled {
  cursor: default !important;
}
/* Icons
----------------------------------*/
/* states and images */
.ui-icon {
  display: block;
  text-indent: -99999px;
  overflow: hidden;
  background-repeat: no-repeat;
}
/* Misc visuals
----------------------------------*/
/* Overlays */
.ui-widget-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.ui-resizable {
  position: relative;
}
.ui-resizable-handle {
  position: absolute;
  font-size: 0.1px;
  display: block;
}
.ui-resizable-disabled .ui-resizable-handle,
.ui-resizable-autohide .ui-resizable-handle {
  display: none;
}
.ui-resizable-n {
  cursor: n-resize;
  height: 7px;
  width: 100%;
  top: -5px;
  left: 0;
}
.ui-resizable-s {
  cursor: s-resize;
  height: 7px;
  width: 100%;
  bottom: -5px;
  left: 0;
}
.ui-resizable-e {
  cursor: e-resize;
  width: 7px;
  right: -5px;
  top: 0;
  height: 100%;
}
.ui-resizable-w {
  cursor: w-resize;
  width: 7px;
  left: -5px;
  top: 0;
  height: 100%;
}
.ui-resizable-se {
  cursor: se-resize;
  width: 12px;
  height: 12px;
  right: 1px;
  bottom: 1px;
}
.ui-resizable-sw {
  cursor: sw-resize;
  width: 9px;
  height: 9px;
  left: -5px;
  bottom: -5px;
}
.ui-resizable-nw {
  cursor: nw-resize;
  width: 9px;
  height: 9px;
  left: -5px;
  top: -5px;
}
.ui-resizable-ne {
  cursor: ne-resize;
  width: 9px;
  height: 9px;
  right: -5px;
  top: -5px;
}
.ui-selectable-helper {
  position: absolute;
  z-index: 100;
  border: 1px dotted black;
}
.ui-datepicker {
  width: 17em;
  padding: 0.2em 0.2em 0;
  display: none;
}
.ui-datepicker .ui-datepicker-header {
  position: relative;
  padding: 0.2em 0;
}
.ui-datepicker .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-next {
  position: absolute;
  top: 2px;
  width: 1.8em;
  height: 1.8em;
}
.ui-datepicker .ui-datepicker-prev-hover,
.ui-datepicker .ui-datepicker-next-hover {
  top: 1px;
}
.ui-datepicker .ui-datepicker-prev {
  left: 2px;
}
.ui-datepicker .ui-datepicker-next {
  right: 2px;
}
.ui-datepicker .ui-datepicker-prev-hover {
  left: 1px;
}
.ui-datepicker .ui-datepicker-next-hover {
  right: 1px;
}
.ui-datepicker .ui-datepicker-prev span,
.ui-datepicker .ui-datepicker-next span {
  display: block;
  position: absolute;
  left: 50%;
  margin-left: -8px;
  top: 50%;
  margin-top: -8px;
}
.ui-datepicker .ui-datepicker-title {
  margin: 0 2.3em;
  line-height: 1.8em;
  text-align: center;
  color: #ffffff;
}
.ui-datepicker .ui-datepicker-title select {
  color: #000000;
}
.ui-datepicker .ui-datepicker-title select {
  font-size: 1em;
  margin: 1px 0;
}
.ui-datepicker select.ui-datepicker-month-year {
  width: 100%;
}
.ui-datepicker select.ui-datepicker-month,
.ui-datepicker select.ui-datepicker-year {
  width: 49%;
}
.ui-datepicker table {
  width: 100%;
  font-size: 0.9em;
  border-collapse: collapse;
  margin: 0 0 0.4em;
}
.ui-datepicker th {
  padding: 0.7em 0.3em;
  text-align: center;
  font-weight: bold;
  border: 0;
}
.ui-datepicker td {
  border: 0;
  padding: 1px;
}
.ui-datepicker td span,
.ui-datepicker td a {
  display: block;
  padding: 0.2em;
  text-align: right;
  text-decoration: none;
}
.ui-datepicker .ui-datepicker-buttonpane {
  background-image: none;
  margin: 0.7em 0 0 0;
  padding: 0 0.2em;
  border-left: 0;
  border-right: 0;
  border-bottom: 0;
}
.ui-datepicker .ui-datepicker-buttonpane button {
  float: right;
  margin: 0.5em 0.2em 0.4em;
  cursor: pointer;
  padding: 0.2em 0.6em 0.3em 0.6em;
  width: auto;
  overflow: visible;
}
.ui-datepicker .ui-datepicker-buttonpane button.ui-datepicker-current {
  float: left;
}
/* with multiple calendars */
.ui-datepicker.ui-datepicker-multi {
  width: auto;
}
.ui-datepicker-multi .ui-datepicker-group {
  float: left;
}
.ui-datepicker-multi .ui-datepicker-group table {
  width: 95%;
  margin: 0 auto 0.4em;
}
.ui-datepicker-multi-2 .ui-datepicker-group {
  width: 50%;
}
.ui-datepicker-multi-3 .ui-datepicker-group {
  width: 33.3%;
}
.ui-datepicker-multi-4 .ui-datepicker-group {
  width: 25%;
}
.ui-datepicker-multi .ui-datepicker-group-last .ui-datepicker-header,
.ui-datepicker-multi .ui-datepicker-group-middle .ui-datepicker-header {
  border-left-width: 0;
}
.ui-datepicker-multi .ui-datepicker-buttonpane {
  clear: left;
}
.ui-datepicker-row-break {
  clear: both;
  width: 100%;
  font-size: 0;
}
/* RTL support */
.ui-datepicker-rtl {
  direction: rtl;
}
.ui-datepicker-rtl .ui-datepicker-prev {
  right: 2px;
  left: auto;
}
.ui-datepicker-rtl .ui-datepicker-next {
  left: 2px;
  right: auto;
}
.ui-datepicker-rtl .ui-datepicker-prev:hover {
  right: 1px;
  left: auto;
}
.ui-datepicker-rtl .ui-datepicker-next:hover {
  left: 1px;
  right: auto;
}
.ui-datepicker-rtl .ui-datepicker-buttonpane {
  clear: right;
}
.ui-datepicker-rtl .ui-datepicker-buttonpane button {
  float: left;
}
.ui-datepicker-rtl .ui-datepicker-buttonpane button.ui-datepicker-current,
.ui-datepicker-rtl .ui-datepicker-group {
  float: right;
}
.ui-datepicker-rtl .ui-datepicker-group-last .ui-datepicker-header,
.ui-datepicker-rtl .ui-datepicker-group-middle .ui-datepicker-header {
  border-right-width: 0;
  border-left-width: 1px;
}
/* Component containers
----------------------------------*/
.ui-widget {
  font-family: Arial, sans-serif;
  font-size: 1.1em;
}
.ui-widget .ui-widget {
  font-size: 1em;
}
.ui-widget input,
.ui-widget select,
.ui-widget textarea,
.ui-widget button {
  font-family: Arial, sans-serif;
  font-size: 1em;
}
.ui-widget-content {
  border: 1px solid #eeeeee;
  background: #ffffff url(images/ui-bg_flat_75_ffffff_40x100.png) 50% 50% repeat-x;
  color: #333333;
}
.ui-widget-content a {
  color: #333333;
}
.ui-datepicker-header {
  border: 1px solid #e3a1a1;
  background: #b80108 url(images/ui-bg_highlight-soft_15_b80108_1x100.png) 50% 50% repeat-x;
  color: #ffffff;
  font-weight: bold;
}
.ui-datepicker-header a {
  color: #ffffff;
}
/* Interaction states
----------------------------------*/
.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-datepicker-header .ui-state-default {
  border: 1px solid #b80108;
  background: #b80108 url(images/ui-bg_flat_100_b80108_40x100.png) 50% 50% repeat-x;
  font-weight: bold;
  color: #ffffff;
}
.ui-state-default a,
.ui-state-default a:link,
.ui-state-default a:visited {
  color: #ffffff;
  text-decoration: none;
}
.ui-state-hover,
.ui-widget-content .ui-state-hover,
.ui-datepicker-header .ui-state-hover,
.ui-state-focus,
.ui-widget-content .ui-state-focus,
.ui-datepicker-header .ui-state-focus {
  border: 1px solid #a00409;
  background: #a00409 url(images/ui-bg_flat_100_a00409_40x100.png) 50% 50% repeat-x;
  font-weight: bold;
  color: #ffffff;
}
.ui-state-hover a,
.ui-state-hover a:hover,
.ui-state-hover a:link,
.ui-state-hover a:visited {
  color: #ffffff;
  text-decoration: none;
}
.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-datepicker-header .ui-state-active {
  border: 1px solid #b80108;
  background: #ffffff url(images/ui-bg_flat_65_ffffff_40x100.png) 50% 50% repeat-x;
  font-weight: bold;
  color: #b80108;
}
.ui-state-active a,
.ui-state-active a:link,
.ui-state-active a:visited {
  color: #b80108;
  text-decoration: none;
}
/* Interaction Cues
----------------------------------*/
.ui-state-highlight,
.ui-widget-content .ui-state-highlight,
.ui-datepicker-header .ui-state-highlight {
  border: 1px solid #a00409;
  background: #f8ed93 url(images/ui-bg_flat_100_f8ed93_40x100.png) 50% 50% repeat-x;
  color: #a00409;
}
.ui-state-highlight a,
.ui-widget-content .ui-state-highlight a,
.ui-datepicker-header .ui-state-highlight a {
  color: #a00409;
}
.ui-state-error,
.ui-widget-content .ui-state-error,
.ui-datepicker-header .ui-state-error {
  border: 1px solid #cc0000;
  background: #f3d8d8 url(images/ui-bg_diagonals-thick_75_f3d8d8_40x40.png) 50% 50% repeat;
  color: #2e2e2e;
}
.ui-state-error a,
.ui-widget-content .ui-state-error a,
.ui-datepicker-header .ui-state-error a {
  color: #2e2e2e;
}
.ui-state-error-text,
.ui-widget-content .ui-state-error-text,
.ui-datepicker-header .ui-state-error-text {
  color: #2e2e2e;
}
.ui-priority-primary,
.ui-widget-content .ui-priority-primary,
.ui-datepicker-header .ui-priority-primary {
  font-weight: bold;
}
.ui-priority-secondary,
.ui-widget-content .ui-priority-secondary,
.ui-datepicker-header .ui-priority-secondary {
  opacity: 0.7;
  filter: alpha(opacity=70);
  font-weight: normal;
}
.ui-state-disabled,
.ui-widget-content .ui-state-disabled,
.ui-datepicker-header .ui-state-disabled {
  opacity: 0.35;
  filter: alpha(opacity=35);
  background-image: none;
}
.ui-state-disabled .ui-icon {
  filter: alpha(opacity=35);
  /* For IE8 - See #6059 */
}
/* Icons
----------------------------------*/
/* states and images */
.ui-icon {
  width: 16px;
  height: 16px;
}
.ui-icon,
.ui-widget-content .ui-icon {
  background-image: url(images/ui-icons_cc0000_256x240.png);
}
.ui-datepicker-header .ui-icon {
  background-image: url(images/ui-icons_ffffff_256x240.png);
}
.ui-state-default .ui-icon {
  background-image: url(images/ui-icons_ffffff_256x240.png);
}
.ui-state-hover .ui-icon,
.ui-state-focus .ui-icon {
  background-image: url(images/ui-icons_a00409_256x240.png);
}
.ui-state-active .ui-icon {
  background-image: url(images/ui-icons_b80108_256x240.png);
}
.ui-state-highlight .ui-icon {
  background-image: url(images/ui-icons_b80108_256x240.png);
}
.ui-state-error .ui-icon,
.ui-state-error-text .ui-icon {
  background-image: url(images/ui-icons_cc0000_256x240.png);
}
/* positioning */
.ui-icon-blank {
  background-position: 16px 16px;
}
.ui-icon-carat-1-n {
  background-position: 0 0;
}
.ui-icon-carat-1-ne {
  background-position: -16px 0;
}
.ui-icon-carat-1-e {
  background-position: -32px 0;
}
.ui-icon-carat-1-se {
  background-position: -48px 0;
}
.ui-icon-carat-1-s {
  background-position: -64px 0;
}
.ui-icon-carat-1-sw {
  background-position: -80px 0;
}
.ui-icon-carat-1-w {
  background-position: -96px 0;
}
.ui-icon-carat-1-nw {
  background-position: -112px 0;
}
.ui-icon-carat-2-n-s {
  background-position: -128px 0;
}
.ui-icon-carat-2-e-w {
  background-position: -144px 0;
}
.ui-icon-triangle-1-n {
  background-position: 0 -16px;
}
.ui-icon-triangle-1-ne {
  background-position: -16px -16px;
}
.ui-icon-triangle-1-e {
  background-position: -32px -16px;
}
.ui-icon-triangle-1-se {
  background-position: -48px -16px;
}
.ui-icon-triangle-1-s {
  background-position: -64px -16px;
}
.ui-icon-triangle-1-sw {
  background-position: -80px -16px;
}
.ui-icon-triangle-1-w {
  background-position: -96px -16px;
}
.ui-icon-triangle-1-nw {
  background-position: -112px -16px;
}
.ui-icon-triangle-2-n-s {
  background-position: -128px -16px;
}
.ui-icon-triangle-2-e-w {
  background-position: -144px -16px;
}
.ui-icon-arrow-1-n {
  background-position: 0 -32px;
}
.ui-icon-arrow-1-ne {
  background-position: -16px -32px;
}
.ui-icon-arrow-1-e {
  background-position: -32px -32px;
}
.ui-icon-arrow-1-se {
  background-position: -48px -32px;
}
.ui-icon-arrow-1-s {
  background-position: -64px -32px;
}
.ui-icon-arrow-1-sw {
  background-position: -80px -32px;
}
.ui-icon-arrow-1-w {
  background-position: -96px -32px;
}
.ui-icon-arrow-1-nw {
  background-position: -112px -32px;
}
.ui-icon-arrow-2-n-s {
  background-position: -128px -32px;
}
.ui-icon-arrow-2-ne-sw {
  background-position: -144px -32px;
}
.ui-icon-arrow-2-e-w {
  background-position: -160px -32px;
}
.ui-icon-arrow-2-se-nw {
  background-position: -176px -32px;
}
.ui-icon-arrowstop-1-n {
  background-position: -192px -32px;
}
.ui-icon-arrowstop-1-e {
  background-position: -208px -32px;
}
.ui-icon-arrowstop-1-s {
  background-position: -224px -32px;
}
.ui-icon-arrowstop-1-w {
  background-position: -240px -32px;
}
.ui-icon-arrowthick-1-n {
  background-position: 0 -48px;
}
.ui-icon-arrowthick-1-ne {
  background-position: -16px -48px;
}
.ui-icon-arrowthick-1-e {
  background-position: -32px -48px;
}
.ui-icon-arrowthick-1-se {
  background-position: -48px -48px;
}
.ui-icon-arrowthick-1-s {
  background-position: -64px -48px;
}
.ui-icon-arrowthick-1-sw {
  background-position: -80px -48px;
}
.ui-icon-arrowthick-1-w {
  background-position: -96px -48px;
}
.ui-icon-arrowthick-1-nw {
  background-position: -112px -48px;
}
.ui-icon-arrowthick-2-n-s {
  background-position: -128px -48px;
}
.ui-icon-arrowthick-2-ne-sw {
  background-position: -144px -48px;
}
.ui-icon-arrowthick-2-e-w {
  background-position: -160px -48px;
}
.ui-icon-arrowthick-2-se-nw {
  background-position: -176px -48px;
}
.ui-icon-arrowthickstop-1-n {
  background-position: -192px -48px;
}
.ui-icon-arrowthickstop-1-e {
  background-position: -208px -48px;
}
.ui-icon-arrowthickstop-1-s {
  background-position: -224px -48px;
}
.ui-icon-arrowthickstop-1-w {
  background-position: -240px -48px;
}
.ui-icon-arrowreturnthick-1-w {
  background-position: 0 -64px;
}
.ui-icon-arrowreturnthick-1-n {
  background-position: -16px -64px;
}
.ui-icon-arrowreturnthick-1-e {
  background-position: -32px -64px;
}
.ui-icon-arrowreturnthick-1-s {
  background-position: -48px -64px;
}
.ui-icon-arrowreturn-1-w {
  background-position: -64px -64px;
}
.ui-icon-arrowreturn-1-n {
  background-position: -80px -64px;
}
.ui-icon-arrowreturn-1-e {
  background-position: -96px -64px;
}
.ui-icon-arrowreturn-1-s {
  background-position: -112px -64px;
}
.ui-icon-arrowrefresh-1-w {
  background-position: -128px -64px;
}
.ui-icon-arrowrefresh-1-n {
  background-position: -144px -64px;
}
.ui-icon-arrowrefresh-1-e {
  background-position: -160px -64px;
}
.ui-icon-arrowrefresh-1-s {
  background-position: -176px -64px;
}
.ui-icon-arrow-4 {
  background-position: 0 -80px;
}
.ui-icon-arrow-4-diag {
  background-position: -16px -80px;
}
.ui-icon-extlink {
  background-position: -32px -80px;
}
.ui-icon-newwin {
  background-position: -48px -80px;
}
.ui-icon-refresh {
  background-position: -64px -80px;
}
.ui-icon-shuffle {
  background-position: -80px -80px;
}
.ui-icon-transfer-e-w {
  background-position: -96px -80px;
}
.ui-icon-transferthick-e-w {
  background-position: -112px -80px;
}
.ui-icon-folder-collapsed {
  background-position: 0 -96px;
}
.ui-icon-folder-open {
  background-position: -16px -96px;
}
.ui-icon-document {
  background-position: -32px -96px;
}
.ui-icon-document-b {
  background-position: -48px -96px;
}
.ui-icon-note {
  background-position: -64px -96px;
}
.ui-icon-mail-closed {
  background-position: -80px -96px;
}
.ui-icon-mail-open {
  background-position: -96px -96px;
}
.ui-icon-suitcase {
  background-position: -112px -96px;
}
.ui-icon-comment {
  background-position: -128px -96px;
}
.ui-icon-person {
  background-position: -144px -96px;
}
.ui-icon-print {
  background-position: -160px -96px;
}
.ui-icon-trash {
  background-position: -176px -96px;
}
.ui-icon-locked {
  background-position: -192px -96px;
}
.ui-icon-unlocked {
  background-position: -208px -96px;
}
.ui-icon-bookmark {
  background-position: -224px -96px;
}
.ui-icon-tag {
  background-position: -240px -96px;
}
.ui-icon-home {
  background-position: 0 -112px;
}
.ui-icon-flag {
  background-position: -16px -112px;
}
.ui-icon-calendar {
  background-position: -32px -112px;
}
.ui-icon-cart {
  background-position: -48px -112px;
}
.ui-icon-pencil {
  background-position: -64px -112px;
}
.ui-icon-clock {
  background-position: -80px -112px;
}
.ui-icon-disk {
  background-position: -96px -112px;
}
.ui-icon-calculator {
  background-position: -112px -112px;
}
.ui-icon-zoomin {
  background-position: -128px -112px;
}
.ui-icon-zoomout {
  background-position: -144px -112px;
}
.ui-icon-search {
  background-position: -160px -112px;
}
.ui-icon-wrench {
  background-position: -176px -112px;
}
.ui-icon-gear {
  background-position: -192px -112px;
}
.ui-icon-heart {
  background-position: -208px -112px;
}
.ui-icon-star {
  background-position: -224px -112px;
}
.ui-icon-link {
  background-position: -240px -112px;
}
.ui-icon-cancel {
  background-position: 0 -128px;
}
.ui-icon-plus {
  background-position: -16px -128px;
}
.ui-icon-plusthick {
  background-position: -32px -128px;
}
.ui-icon-minus {
  background-position: -48px -128px;
}
.ui-icon-minusthick {
  background-position: -64px -128px;
}
.ui-icon-close {
  background-position: -80px -128px;
}
.ui-icon-closethick {
  display: none;
}
.ui-icon-key {
  background-position: -112px -128px;
}
.ui-icon-lightbulb {
  background-position: -128px -128px;
}
.ui-icon-scissors {
  background-position: -144px -128px;
}
.ui-icon-clipboard {
  background-position: -160px -128px;
}
.ui-icon-copy {
  background-position: -176px -128px;
}
.ui-icon-contact {
  background-position: -192px -128px;
}
.ui-icon-image {
  background-position: -208px -128px;
}
.ui-icon-video {
  background-position: -224px -128px;
}
.ui-icon-script {
  background-position: -240px -128px;
}
.ui-icon-alert {
  background-position: 0 -144px;
}
.ui-icon-info {
  background-position: -16px -144px;
}
.ui-icon-notice {
  background-position: -32px -144px;
}
.ui-icon-help {
  background-position: -48px -144px;
}
.ui-icon-check {
  background-position: -64px -144px;
}
.ui-icon-bullet {
  background-position: -80px -144px;
}
.ui-icon-radio-on {
  background-position: -96px -144px;
}
.ui-icon-radio-off {
  background-position: -112px -144px;
}
.ui-icon-pin-w {
  background-position: -128px -144px;
}
.ui-icon-pin-s {
  background-position: -144px -144px;
}
.ui-icon-play {
  background-position: 0 -160px;
}
.ui-icon-pause {
  background-position: -16px -160px;
}
.ui-icon-seek-next {
  background-position: -32px -160px;
}
.ui-icon-seek-prev {
  background-position: -48px -160px;
}
.ui-icon-seek-end {
  background-position: -64px -160px;
}
.ui-icon-seek-start {
  background-position: -80px -160px;
}
/* ui-icon-seek-first is deprecated, use ui-icon-seek-start instead */
.ui-icon-seek-first {
  background-position: -80px -160px;
}
.ui-icon-stop {
  background-position: -96px -160px;
}
.ui-icon-eject {
  background-position: -112px -160px;
}
.ui-icon-volume-off {
  background-position: -128px -160px;
}
.ui-icon-volume-on {
  background-position: -144px -160px;
}
.ui-icon-power {
  background-position: 0 -176px;
}
.ui-icon-signal-diag {
  background-position: -16px -176px;
}
.ui-icon-signal {
  background-position: -32px -176px;
}
.ui-icon-battery-0 {
  background-position: -48px -176px;
}
.ui-icon-battery-1 {
  background-position: -64px -176px;
}
.ui-icon-battery-2 {
  background-position: -80px -176px;
}
.ui-icon-battery-3 {
  background-position: -96px -176px;
}
.ui-icon-circle-plus {
  background-position: 0 -192px;
}
.ui-icon-circle-minus {
  background-position: -16px -192px;
}
.ui-icon-circle-close {
  background-position: -32px -192px;
}
.ui-icon-circle-triangle-e {
  background-position: -48px -192px;
}
.ui-icon-circle-triangle-s {
  background-position: -64px -192px;
}
.ui-icon-circle-triangle-w {
  background-position: -80px -192px;
}
.ui-icon-circle-triangle-n {
  background-position: -96px -192px;
}
.ui-icon-circle-arrow-e {
  background-position: -112px -192px;
}
.ui-icon-circle-arrow-s {
  background-position: -128px -192px;
}
.ui-icon-circle-arrow-w {
  background-position: -144px -192px;
}
.ui-icon-circle-arrow-n {
  background-position: -160px -192px;
}
.ui-icon-circle-zoomin {
  background-position: -176px -192px;
}
.ui-icon-circle-zoomout {
  background-position: -192px -192px;
}
.ui-icon-circle-check {
  background-position: -208px -192px;
}
.ui-icon-circlesmall-plus {
  background-position: 0 -208px;
}
.ui-icon-circlesmall-minus {
  background-position: -16px -208px;
}
.ui-icon-circlesmall-close {
  background-position: -32px -208px;
}
.ui-icon-squaresmall-plus {
  background-position: -48px -208px;
}
.ui-icon-squaresmall-minus {
  background-position: -64px -208px;
}
.ui-icon-squaresmall-close {
  background-position: -80px -208px;
}
.ui-icon-grip-dotted-vertical {
  background-position: 0 -224px;
}
.ui-icon-grip-dotted-horizontal {
  background-position: -16px -224px;
}
.ui-icon-grip-solid-vertical {
  background-position: -32px -224px;
}
.ui-icon-grip-solid-horizontal {
  background-position: -48px -224px;
}
.ui-icon-gripsmall-diagonal-se {
  background-position: -64px -224px;
}
.ui-icon-grip-diagonal-se {
  background-position: -80px -224px;
}
/* Misc visuals
----------------------------------*/
/* Corner radius */
.ui-corner-all,
.ui-corner-top,
.ui-corner-left,
.ui-corner-tl {
  border-top-left-radius: 10px;
}
.ui-corner-all,
.ui-corner-top,
.ui-corner-right,
.ui-corner-tr {
  border-top-right-radius: 10px;
}
.ui-corner-all,
.ui-corner-bottom,
.ui-corner-left,
.ui-corner-bl {
  border-bottom-left-radius: 10px;
}
.ui-corner-all,
.ui-corner-bottom,
.ui-corner-right,
.ui-corner-br {
  border-bottom-right-radius: 10px;
}
/* Overlays */
.ui-widget-overlay {
  background: #a6a6a6 url(images/ui-bg_dots-small_65_a6a6a6_2x2.png) 50% 50% repeat;
  opacity: 0.4;
  filter: alpha(opacity=40);
}
.ui-widget-shadow {
  margin: -8px 0 0 -8px;
  padding: 8px;
  background: #333333 url(images/ui-bg_flat_0_333333_40x100.png) 50% 50% repeat-x;
  opacity: 0.1;
  filter: alpha(opacity=10);
  border-radius: 8px;
}
